import React from "react"
import {Box,Heading,Text,SimpleGrid} from '@chakra-ui/react'
import Link from '../components/gatsbylink'
import {motion} from 'framer-motion'
import MeinSeo from '../components/SEO'

export default function Tickets(){
return(
    <motion.div
        initial={{
            opacity:0
        }}
        animate={{opacity:1 }}
        transition={{duration:1}}
        >
             <MeinSeo title="Tickets für das Theaterfestival An den Ufern der Poesie" description="Theaterfestival der Poesie im Oberen Mittelrheintal - Ticketverkauf vor Ort und Online" />
   <Box pt="10" minHeight="800" margin="auto" width="90vw" px="5vw" maxWidth="1100" >
    <SimpleGrid py="60px" gap={6} columns={[1,1,2]}>
    <Box p="3"  width="100%" >
     <Heading variant="title">Ticket-Verkauf vor Ort</Heading>
      <Text variant="solid">
     <span style={{fontWeight:500}}>Bacharach:</span> <br/>
     Rhein-Nahe-Touristik 
     </Text>
     <Text variant="solid"><span style={{fontWeight:500}}>Oberwesel:</span> <br/>
    Tourist Information und Kulturhaus Oberwesel</Text>
     <Text variant="solid"><span style={{fontWeight:500}}>Lorch:</span> <br/>
    Tourist Information</Text>
     <Text variant="solid"><span style={{fontWeight:500}}>Loreley-Plateau:</span> <br/>
    Loreley Touristik
    </Text>
    
     
    <Text variant="solid"><span style={{fontWeight:500}}>St.Goar:</span> <br/>
    Tourist Information</Text>
    <Heading variant="title">Ticketverkauf Online</Heading>
    <Link href="https://www.ticket-regional.de/events.php?mysearchEventtype=Zweckverband%20Welterbe%20Oberes%20Mittelrheintal"> <Text variant="solid" color="blue">
    &rarr; Ticketverkauf Online
        </Text></Link>
    </Box>
    <Box p="3">
        <Heading variant="title">Preise</Heading>
         <Text variant="solid"><span style={{fontWeight:500}}>Heinrich Heine:</span> <br/>
         Ich rede von der Cholera.<br/>Tickets VVK 22,50 € / erm. 11,50 €
        </Text>
         <Text variant="solid"><span style={{fontWeight:500}}>Heinrich Heine: Der Rabbi von Bacharach</span><br/>Tickets VVK 28,00 € / erm. 13,70 €</Text>
         <Text variant="solid"><span style={{fontWeight:500}}>Annette von Droste-Hülshoff: Die Judenbuche</span><br/>Tickets VVK 22,50 € / erm. 11,50 €</Text>
         <Text variant="solid"><span style={{fontWeight:500}}> Podium Szene und Dialog. "Shylock"</span><br/>Eintritt frei</Text>
         <Text variant="solid"><span style={{fontWeight:500}}>In Auschwitz gab es keine Vögel</span><br/>Tickets VVK 22,50 € / erm. 11,50 €</Text>
         <Text variant="solid"><span style={{fontWeight:500}}>Sankt Werner - 700 Jahre antisemitische Fake News</span><br/>Eintritt frei</Text>
    </Box>
    </SimpleGrid>
    </Box>
    </motion.div>
)    


}